import { useRef, useState } from 'react';
import { SwitchTransition, CSSTransition } from 'react-transition-group';

interface HoverButtonProps {
    children: JSX.Element | string
    hoverText: JSX.Element | string
    className?: string
}

const HoverButton = ({ children, hoverText, className }: HoverButtonProps) => {
    const [state, setState] = useState(false)
    const helloRef = useRef<any>(null);
    const goodbyeRef = useRef<any>(null);
    const nodeRef = state ? goodbyeRef : helloRef;
    className = `${className} ${state ? ' Button__Hovered' : ''}`

    return <div className={`Button Button__Hover ${className}`} onMouseEnter={() => setState(true)} onMouseLeave={() => setState(false)}>
        <SwitchTransition mode={'out-in'}>
            <CSSTransition
                key={`${state}`}
                nodeRef={nodeRef}
                addEndListener={function (done: any) {
                    nodeRef.current.addEventListener("transitionend", done, false);
                }}
                classNames="fade"
            >
                <span ref={nodeRef} className="button-container">
                    {!state ? children : hoverText}
                </span>
            </CSSTransition>
        </SwitchTransition>
    </div>
}

export default HoverButton