export const es = {
    ComingSoon: {
        'Postallcode': 'Postallcode',
        'Home': 'Inicio',
        'What is it?': '¿Qué es?',
        'Shops': 'Comercios',
        'Techology': 'Tecnología',
        'Go to the app': 'Ir a la App',
        'Coming soon': 'Pronto',
        'In the <strong>{{moment}}</strong>, at the <strong>{{place}}</strong>'
            : 'En el <strong>{{moment}}</strong>, en el <strong>{{place}}</strong>',
        'best moment': 'mejor momento',
        'best time': 'mejor lugar',
        'Transform your online shopping experience'
            : 'Transforma tu experiencia de compra online',
        'Enter your email to find out more information before the launch'
            : 'Ingresa tu correo electrónico para más información del lanzamiento',
        'Download App': 'Descargar app',
        'Get started': 'Más información',
        'Thank you': 'Gracias',
        'Thank you, you will be the first to know everything about Postall'
            : 'Serás prioridad en nuestro lanzamiento de Postall.'
    }
}