import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { useEffect } from 'react';
import { HomeView } from "./Views";

import './I18n'
import './App.scss';

const client = new ApolloClient({
  uri: process.env.REACT_APP_ENDPOINT || 'https://api.postallcode.com/graphql',
  cache: new InMemoryCache(),
});

function App() {

  useEffect(() => {
    /** @todo Repair when change orientation device */
    const doc = document.documentElement
    doc.style.setProperty('--vh', (window.innerHeight*0.01) + 'px')
  }, [])
  
  return <ApolloProvider client={client}>
    <HomeView />
  </ApolloProvider>
}

export default App;
