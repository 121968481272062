export const en = {
    ComingSoon: {
        'Postallcode': 'Postallcode',
        'Home': 'Home',
        'What is it?': 'What is it?',
        'Shops': 'Shops',
        'Techology': 'Techology',
        'Go to the app': 'Go to the app',
        'Coming soon': 'Coming soon',
        'In the <strong>{{moment}}</strong>, at the <strong>{{place}}</strong>': 'In the <strong>{{moment}}</strong>, at the <strong>{{place}}</strong>',
        'best moment': 'best moment',
        'best time': 'best time',
        'Transform your online shopping experience': 'Transform your online shopping experience',
        'Enter your email to find out more information before the launch': 'Enter your email to find out more information before the launch',
        'Download App': 'Download App',
        'Get started': 'Get started',
        'Thank you': 'Thank you',
        'Thank you, you will be the first to know everything about Postall'
            : 'Thank you, you will be the first to know everything about Postall'
    }
}