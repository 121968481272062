import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector'
import XHR from 'i18next-http-backend'

import { en } from "./en";
import { es } from "./es";

i18n
    .use(XHR)
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        detection: {
            order: ['querystring', 'navigator'],
            lookupQuerystring: 'lng',
            convertDetectedLanguage: (lng: string) => {
                // convertDetectedLanguage: 'Iso15897',
                return lng.substring(0, 2).toLowerCase()
            }

        },
        resources: {
            en,
            es
        },
        fallbackLng: "en",
        supportedLngs: ['en', 'es'],
        interpolation: {
            escapeValue: false
        }
    });
