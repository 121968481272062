import ReCAPTCHA from "react-google-recaptcha";
import Icon from '@mdi/react';
import { createRef, useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { mdiLock, mdiPanRight, mdiLoading } from '@mdi/js';

import { useMobile } from "../../Hooks";
import { AppIcon, HoverButton } from "../../Components";
import { LaunchCreateLead } from "../../Library";
import { useMutation } from "@apollo/client";

type Inputs = {
    email: string
}

export default function HomeView() {
    const { t } = useTranslation('ComingSoon')
    const recaptchaRef = createRef();
    const [send, { data, loading, error, called }] = useMutation(LaunchCreateLead)

    const [formLoading, setFormLoading] = useState(false)
    
    const isMobile = useMobile()
    const {
        register,
        handleSubmit,
        // watch,
        // formState: { errors },
    } = useForm<Inputs>()

    useEffect(() => {
        console.log(data)
    }, [data])

    useEffect(() => {
        console.log(error)
    }, [error])

    useEffect(() => {
        setFormLoading(loading)
    }, [loading])

    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        setFormLoading(true)
        // @ts-ignore
        const token = await recaptchaRef?.current?.execute()
        
        send({
            variables: {
                LaunchCreateLeadInput: {
                    email: data.email,
                    token
                }
            }
        })
    }

    return <div className={`App ${isMobile ? 'isMobile' : 'isDesktop'}`}>
        <header className="Header">
            <div className="Header__Logo">
                <AppIcon />
                <h1>{t('Postallcode')}</h1>
            </div>
            {/* <nav className="Header__Menu">
          <ul>
            <li>{t('Home')}</li>
            <li>{t('What is it?')}</li>
            <li>{t('Shops')}</li>
            <li>{t('Techology')}</li>
          </ul>
        </nav> */}
            <div className="Header__CTO">
                {/* <button type="button">{t('Go to the app')}</button> */}
                <HoverButton hoverText={t('Coming soon')}>
                    {t('Go to the app')}
                </HoverButton>
            </div>
        </header>

        <main className="Banner">
            <video className="Banner__Background" autoPlay playsInline muted loop>
                <source src={require('../../Assets/Home__Background.mp4')} type="video/mp4" />
            </video>
            <div className="Banner__Title">
                <h2 dangerouslySetInnerHTML={{
                    __html: t('In the <strong>{{moment}}</strong>, at the <strong>{{place}}</strong>', {
                        moment: t('best moment'),
                        place: t('best time')
                    })
                }
                } />
                <h3>{t('Transform your online shopping experience')}</h3>
            </div>
            {(!formLoading && !called) ? <form className="Banner__Form" onSubmit={handleSubmit(onSubmit)}>
                <p>{t('Enter your email to find out more information before the launch')}</p>
                <div className='Banner__Form__InputGroup'>
                    <label htmlFor="email">
                        <Icon path={mdiLock} size={1} className="Banner__Form__InputGroup__AccessoryLeft" />
                    </label>
                    <input autoFocus type="email" id="email" placeholder='founder@postallcode.com' {...register('email', {
                        required: true,
                        pattern: {
                            value: /\S+@\S+\.\S+/,
                            message: t("Entered value does not match email format"),
                        },
                    })} />
                </div>
                <div className="Banner__Form__Buttons">
                    <HoverButton className="Button__Outlined__Light" hoverText={t('Coming soon')}>
                        {t('Download App')}
                    </HoverButton>
                    <button type='submit'>
                        {t('Get started')}
                        <Icon path={mdiPanRight} size={1} />
                    </button>
                </div>
            </form> : <div className="Banner__Form">
                {formLoading ? <>
                    <Icon className="Icon__Spinner" path={mdiLoading} size={2} />
                </> : <>
                    <h4>{t('Thank you')}</h4>
                    <h5>{t('Thank you, you will be the first to know everything about Postall')}</h5>
                </>}
            </div>}

        </main>

        <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}
            size='invisible'
            // @ts-ignore
            ref={recaptchaRef}
        />
    </div>
}