import * as React from "react"
import { SVGProps } from "react"
const AppIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={23}
        height={24}
        fill="none"
        viewBox="0 0 23 24"
        {...props}
    >
        <path
            fill="#FF6D01"
            d="M3.686 13.744V24h.203a5.022 5.022 0 0 0 5.018-5.026v-5.23h-5.22ZM18.978 0H7.337a3.654 3.654 0 0 0-3.651 3.656V5.23h13.722v12.41h1.57a3.653 3.653 0 0 0 3.65-3.657V3.656A3.654 3.654 0 0 0 18.979 0Z"
        />
        <path fill="#FFE800" d="M10.24 17.598h5.819v-5.23h-5.82v5.23Z" />
        <path fill="#FFE800" d="M3.65 12.41h6.59V6.581H3.65v5.83Z" />
        <path
            fill="#FF9501"
            d="M0 7.384c0 2.776 1.634 5.026 3.65 5.026V6.581H0v.803ZM10.24 17.64a5.022 5.022 0 0 0 5.017 5.026h.802V17.64h-5.82ZM10.24 12.41h5.819V6.581h-5.82v5.83Z"
        />
    </svg>
)
export default AppIcon
